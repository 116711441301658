.wrapper {
  width: 360px;
  font-size: 13px;
}

.shop {
  padding: 0 0 15px 0;
  margin: 0 0 15px 0;
  border-bottom: #d3d5d9 1px dashed;
  flex-wrap: nowrap;
}

.logo {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.header {
  margin-bottom: 20px;
  text-align: center;
  position: relative;

  &Actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.group {
  padding-bottom: 9px;
  margin-bottom: 13px;
  border-bottom: #d3d5d9 1px dashed;
  position: relative;

  &Center {
    text-align: center;
  }
}

.row {
  margin: 2px 0;

  &Value {
    text-align: right;
  }
}

.total {
  font-weight: 500;
  font-size: 18px;
}

.image {
  margin-top: 20px;
}

.barcode {
  margin-top: 10px;
  max-width: 300px;
}

.description {
  margin: 0;
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
}

.link {
  @extend .description;

  display: block;
  text-decoration: underline;
  color: #57b7da;

  &:hover {
    text-decoration: none;
  }
}

.footer {
  margin: 20px 0 0 0;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    width: 100%;
    max-width: 360px;
  }

  .row {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    .column {
      // width: auto !important;
      margin-bottom: 0 !important;
    }
  }
}

@media print {
  .wrapper {
    margin: 50px auto;
  }

  .headerActions {
    display: none;
  }
}
