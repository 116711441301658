@use 'sass:math';
@import 'variables';

:global {
  * {
    box-sizing: border-box;

    outline: none;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  html,
  body {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: math.div(20, 14);

    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $content-background;
  }

  html,
  body {
    height: 100%;
    min-height: 100%;
    position: relative;
  }
}

button {
  font-family: 'Rubik', sans-serif;
}
