.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.container {
  display: flex;
  gap: 10px;
  position: relative;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  left: 100%;
  margin-left: 10px;
}

@media screen and (max-width: 640px) {
  .actions {
    display: none;
  }
}
